import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IStore } from '../../../../types/storeTypes'
import { useGetAcquiredReturnStepData } from '../../../../hooks/useGetAcquiredReturnStepData'
import { Loader } from '../../../loader'
import { IconPrint } from '../../../../images/IconPrint'
import { useFormSession } from '../../../../hooks/useFormSession'

export const ConfirmationStep = () => {
  const { t } = useTranslation()
  const confirmationData = useSelector((store: IStore) => store.confirmationData)
  const { stepsLoading } = useGetAcquiredReturnStepData()
  const { stopCheckSession, saveAcquiredReturnSession } = useFormSession()
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const isSpain =
    appointmentInfo.intermediate_save &&
    appointmentInfo.intermediate_save.office &&
    appointmentInfo.intermediate_save.office.country &&
    appointmentInfo.intermediate_save.office.country.toLowerCase() === 'es'
      ? true
      : false

  useEffect(() => {
    stopCheckSession()
    saveAcquiredReturnSession({
      currentStep: 2,
      buyer_id: undefined,
      client_name: undefined,
      returnItems: [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return stepsLoading.includes(4) || !confirmationData.offer_id ? (
    <div className='w-full h-[70vh] flex justify-center items-center'>
      <Loader className='h-[100px]' />
    </div>
  ) : (
    <>
      <div className='flex flex-col'>
        <div className='w-full bg-white rounded-lg min-h-[70vh] max-h-auto mb-4 py-6 overflow-y-auto custom_scroll flex justify-center items-center'>
          <div className='flex flex-col justify-center items-center'>
            <div className=' font-serif text-4xl leading-[43px] text-center'>
              {confirmationData.client_name} {t('Appointment Complete')}!{' '}
              {confirmationData.link ? (
                <span className='whitespace-nowrap'>
                  {t('Offer')}{' '}
                  <a
                    href={confirmationData.link}
                    target='_blank'
                    className='underline underline-offset-4 decoration-2 text-blue-500'
                    rel='noreferrer'
                  >
                    #B{confirmationData.offer_id}
                  </a>
                </span>
              ) : (
                <span className='whitespace-nowrap'>
                  {t('Offer')} #B{confirmationData.offer_id}
                </span>
              )}
            </div>

            <div className='text-lg text-zinc-700 mt-4'>{t('You can close this window')}</div>

            <div className='flex flex-col gap-7 mt-6'>
              {confirmationData.return_form && (
                <div className='flex justify-between items-center w-[380px] gap-4'>
                  <div className=''>{t('Acquired Return Form')}</div>
                  <a
                    href={confirmationData.return_form}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='grey_button !w-[160px] !min-w-[160px]'
                  >
                    <IconPrint />
                    {t('Print')}
                  </a>
                </div>
              )}
              {isSpain && (
                <>
                  {confirmationData.gdpr_url && (
                    <div className='flex justify-between items-center w-[380px] gap-4'>
                      <div className=''>{t('GDPR file')}.pdf</div>
                      <a
                        href={confirmationData.gdpr_url}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='grey_button !w-[160px] !min-w-[160px]'
                      >
                        <IconPrint />
                        {t('Print')}
                      </a>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
