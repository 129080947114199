import React, { useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { SignatureModal } from '../../modals/signatureModal'
import { Loader } from '../../loader'
import { useSelector } from 'react-redux'
import { IImage, IStore } from '../../../types/storeTypes'
import { LOCAL_ACCESS_TOKEN, SESSION_TOKEN } from '../../../utils/consts'
import { useTranslation } from 'react-i18next'
import { IconDraw } from '../../../images/IconDraw'
import { IconFromPhone } from '../../../images/IconFromPhone'
import { IUploadSignatureData, useOfferAgreements } from '../../../hooks/useOfferAgreements'
import { LoadSignatureViaMobileQrCode } from '../../modals/QRCodeModals/loadSignatureViaMobileQrCode'
import { IconPdf } from '../../../images/IconPdf'
import { IconClose } from '../../../images/IconClose'
import { DefaultButton } from '../../defaultButton'
import { useOfferDocs } from '../../../hooks/useOfferDocs'
import { PhotoUploader } from '../photoUploader'
import { ISignatureTab } from '../../../types/additionalTypes'
import { useSignaturePad } from '../../../hooks/useSignaturePad'
import { SignatureEmailModal } from '../../modals/signatureEmailModal'

interface Props {
  isConsignedReturn?: boolean
  isAcquiredReturn?: boolean
  isConsignedConvert?: boolean
  isDropOff?: boolean
  isUnmounting?: boolean
  selectedTab: IUploadSignatureData['agreement']
  signatureAmount?: number
  signatureFile: string | null
  saveLoading: boolean
  tabs: ISignatureTab[]
  emailBtn?: boolean
}

export const SignatureBlock: React.FC<Props> = ({
  isConsignedReturn = false,
  isAcquiredReturn = false,
  isConsignedConvert = false,
  isDropOff = false,
  isUnmounting = false,
  selectedTab,
  signatureAmount = 0,
  signatureFile,
  saveLoading,
  tabs,
  emailBtn = false,
}) => {
  const sessionData = useSelector((store: IStore) => store.sessionData)
  const agreements = useSelector((store: IStore) => store.agreements)
  const acquiredReturn = useSelector((store: IStore) => store.acquiredReturn)
  const consignmentReturn = useSelector((store: IStore) => store.consignmentReturn)
  const { t, i18n } = useTranslation()
  const [showSignatureModal, setShowSignatureModal] = useState(false)
  const [showQrCode, setShowQrCode] = useState(false)
  const [token, setToken] = useState<string>(uuidv4())
  const [showSendEmail, setShowSendEmail] = useState(false)
  const sessionToken = sessionData.sessionId || sessionStorage.getItem(SESSION_TOKEN) || ''
  const { uploadSignature, deleteSignature } = useOfferAgreements()
  const { loadPdf, loadPdfLoading, loadReturnPdf } = useOfferDocs()
  const { startSign, signaturePadLoading } = useSignaturePad((sign) => onSave(sign))

  useEffect(() => {
    if (!showQrCode) setToken(uuidv4())
  }, [showQrCode])

  const onSave = (sign: string) => {
    const data: IUploadSignatureData = {
      signature: sign,
      agreement: selectedTab,
      items: isAcquiredReturn
        ? acquiredReturn.returnItems.map((items) => (items.id ? items.id : 0))
        : isConsignedReturn
          ? consignmentReturn.returnItems.map((items) => (items.id ? items.id : 0))
          : undefined,
      is_unmounting: selectedTab === 'consignment' && isUnmounting ? true : false,
    }
    uploadSignature(data, isConsignedConvert, isConsignedReturn, isAcquiredReturn)
  }

  const onSignedImagesUploaded = (images: IImage[]) => {
    const data: IUploadSignatureData = {
      files: images.map((image) => {
        return { url: image.url, name: image.name }
      }),
      agreement: selectedTab as IUploadSignatureData['agreement'],
      signature: '',
      items: isAcquiredReturn
        ? acquiredReturn.returnItems.map((items) => (items.id ? items.id : 0))
        : isConsignedReturn
          ? consignmentReturn.returnItems.map((items) => (items.id ? items.id : 0))
          : undefined,
    }
    uploadSignature(data, isConsignedConvert, isConsignedReturn, isAcquiredReturn)
  }

  const qrCodeUrl = useMemo(() => {
    let url = `${window.location.origin}/${i18n.language}/add-signature/?signature_type=${selectedTab as IUploadSignatureData['agreement']}&upload_token=${token}&user_token=${sessionStorage.getItem(LOCAL_ACCESS_TOKEN)}&sessionToken=${sessionToken}`

    if (!isDropOff) url += `&amount=${signatureAmount}`
    if (isUnmounting) url += `&is_unmounting=${isUnmounting}`
    if (isConsignedConvert) url += `&isConsignedConvert=true`
    if (isAcquiredReturn) {
      url += `&isAcquiredReturn=true&items=${encodeURIComponent(JSON.stringify(acquiredReturn.returnItems.map((item) => item.id || 0)))}`
    }
    if (isDropOff) url += `&is_drop_off=true`
    if (isConsignedReturn) {
      url += `&isConsignedReturn=true&items=${encodeURIComponent(JSON.stringify(consignmentReturn.returnItems.map((item) => item.id || 0)))}`
    }

    return url
  }, [
    acquiredReturn.returnItems,
    consignmentReturn.returnItems,
    i18n.language,
    isAcquiredReturn,
    isConsignedConvert,
    isConsignedReturn,
    isDropOff,
    isUnmounting,
    selectedTab,
    sessionToken,
    signatureAmount,
    token,
  ])

  const onPdfPrint = () => {
    if (isAcquiredReturn) {
      const data = {
        items: acquiredReturn.returnItems.map((item) => (item.id ? item.id : 0)),
      }
      loadReturnPdf(data)
    } else if (isConsignedReturn) {
      const data = {
        items: consignmentReturn.returnItems.map((item) => (item.id ? item.id : 0)),
      }
      loadReturnPdf(data)
    } else {
      loadPdf(selectedTab === 'purchase' ? 'purchase' : 'consignment', undefined, undefined, isUnmounting)
    }
  }

  const onFileDelete = () => {
    if (isAcquiredReturn) {
      const items = acquiredReturn.returnItems.map((item) => (item.id ? item.id : 0))
      deleteSignature(selectedTab, items)
    } else if (isConsignedReturn) {
      const items = consignmentReturn.returnItems.map((item) => (item.id ? item.id : 0))
      deleteSignature(selectedTab, items)
    } else {
      deleteSignature(selectedTab)
    }
  }

  return (
    <>
      {signatureFile ? (
        <div className='flex w-full items-center justify-end gap-4 lg:gap-12'>
          <div className='text-base font-normal text-zinc-700 whitespace-nowrap flex justify-end'>
            {t('Signed copy')}
          </div>
          <div className='flex-wrap gap-3 flex justify-end relative'>
            {(agreements.deleteSignatureLoading || signaturePadLoading) && (
              <div className='absolute z-10 bg-zinc-500 opacity-20 rounded inset-0 flex justify-center items-center'>
                <Loader className={`h-8 w-8 ml-2`} />
              </div>
            )}
            <div className='flex items-center gap-2'>
              <a href={signatureFile} target='_blank' rel='noreferrer' className='flex max-w-full max-h-full'>
                <IconPdf classname='h-[60px] w-auto' />
              </a>
              <div className='text-xs text-zinc-500 capitalize'>
                {t(tabs.find((tab) => tab.value === selectedTab)?.title || '')}
              </div>
              <button type='button' onClick={onFileDelete} disabled={agreements.deleteSignatureLoading}>
                <IconClose />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className='flex w-full items-center justify-end gap-4 lg:gap-12'>
            <div className='text-base font-normal text-zinc-700 whitespace-nowrap flex justify-end'>
              {t('Signature')}
            </div>

            <div className='flex gap-2 items-center relative'>
              {(agreements.uploadSignatureLoading || signaturePadLoading) && (
                <div className='absolute z-10 bg-zinc-500 opacity-20 rounded inset-0 flex justify-center items-center'>
                  <Loader className={`h-8 w-8 ml-2`} />
                </div>
              )}

              <div className='flex flex-col lg:flex-row gap-2'>
                {emailBtn ? (
                  <DefaultButton
                    title={t('Email')}
                    onClick={() => setShowSendEmail(true)}
                    classname='grey_button !w-[280px] lg:!w-[120px] !h-[50px] lg:!h-[53.6px]'
                    disabled={saveLoading || loadPdfLoading}
                    icon='email'
                  />
                ) : null}
                <div className='flex gap-2'>
                  <DefaultButton
                    title={t('Signature Pad')}
                    classname='identification-image-upload-button !text-base lg:!text-lg !w-[136px] lg:!w-[150px]'
                    onClick={startSign}
                    disabled={saveLoading || signaturePadLoading}
                    loaderColor='fill-red-500'
                    icon='signature'
                  />

                  <button
                    type='button'
                    className='identification-image-upload-button !text-base lg:!text-lg !w-[136px] lg:!w-[150px]'
                    onClick={() => setShowSignatureModal(true)}
                  >
                    <IconDraw />
                    {t('Screen/Mouse')}
                  </button>
                </div>

                <button
                  type='button'
                  className='identification-image-upload-button !text-base lg:!text-lg !w-[280px] lg:!w-[150px]'
                  onClick={() => setShowQrCode(true)}
                >
                  <IconFromPhone />
                  {t('QR Code')}
                </button>
              </div>
            </div>
          </div>
          <div
            className={`flex w-full items-center  ${signatureFile ? ' justify-end gap-4 lg:gap-12' : 'justify-end gap-4 lg:gap-12'}`}
          >
            <div className='text-base font-normal text-zinc-700 whitespace-nowrap flex justify-end'>
              {t('Upload signed copy')}
            </div>
            <div className='flex gap-2 relative flex-col items-start lg:flex-row lg:items-center'>
              {(agreements.uploadSignatureLoading || signaturePadLoading) && (
                <div className='absolute z-10 bg-zinc-500 opacity-20 rounded inset-0 flex justify-center items-center'>
                  <Loader className={`h-8 w-8 ml-2`} />
                </div>
              )}
              <DefaultButton
                title={t('Print')}
                onClick={onPdfPrint}
                classname='grey_button !w-[280px] lg:!w-[150px] !h-[50px] lg:!h-[53.6px]'
                loading={loadPdfLoading}
                disabled={saveLoading || loadPdfLoading}
                icon='print'
              />
              <PhotoUploader
                blockStyle='flex gap-2'
                desktopButtonText='Choose file'
                desktopButtonStyle='identification-image-upload-button !text-base lg:!text-lg !w-[136px] lg:!w-[150px]'
                showQRCodeButton
                qrcodeButtonText='From mobile'
                qrcodeButtonStyle='identification-image-upload-button !text-base lg:!text-lg !w-[136px] lg:!w-[150px]'
                callback={(image) => onSignedImagesUploaded(image)}
                maxFiles={5}
              />
            </div>
          </div>
        </>
      )}

      <SignatureModal
        showed={showSignatureModal}
        closeModal={() => setShowSignatureModal(false)}
        agreement={selectedTab as IUploadSignatureData['agreement']}
        isUnmounting={isUnmounting}
        isAcquiredReturn={isAcquiredReturn}
        isConsignedReturn={isConsignedReturn}
      />

      <LoadSignatureViaMobileQrCode
        visible={showQrCode}
        setVisible={setShowQrCode}
        qrCodeValue={qrCodeUrl}
        token={token}
        signature_type={selectedTab as IUploadSignatureData['agreement']}
        isAcquiredReturn={isAcquiredReturn}
        isConsignedReturn={isConsignedReturn}
      />

      <SignatureEmailModal showed={showSendEmail} closeModal={() => setShowSendEmail(false)} />
    </>
  )
}
